import { useAuth } from '@guider-global/auth-hooks';
import { getCountryList } from '@guider-global/front-end-utils';
import { useOrganization } from '@guider-global/sanity-hooks';
import { Base_languages } from '@guider-global/sanity-types';
import { IFilterModel, Program } from '@guider-global/shared-types';
import {
  Button,
  IMultiSelectChipFilterOption,
  ITableRefs,
  KeyStatList,
  Stack,
  Text,
} from '@guider-global/ui';
import Refresh from '@mui/icons-material/Refresh';
import { Grid as MuiGrid, useMediaQuery, useTheme } from '@mui/material';
import {
  MetricsProgramGroupFilterContainer,
  StackedChartContainer,
} from 'containers';
import { MongoMetricsContainer } from 'containers/MongoMetricsContrainer';
import { useAppContext, useProgramGroupTableContext } from 'context';
import {
  getGroupColumns,
  getGroupKeyStats,
  getGroupSessionColumns,
  getGroupTraineeProfileColumns,
} from 'functions';
import {
  useAdminChartsGroupProgramRelationships,
  useAdminChartsGroupProgramSessions,
  useAdminChartsGroupProgramTraineeProfiles,
  useAdminKPIsGroupProgram,
  useAdminKPIsOrganization,
  useAdminOvertimeCharts,
  useSnackbar,
} from 'hooks';
import { getUpdatedAt } from 'pages/ProgramMetricsPage';
import { useMemo, useRef, useState } from 'react';
import { PageView } from 'views';

export interface IGroupProgramMeticsViewProps {
  program: Program;
  baseLanguage: Base_languages;
}

export function GroupProgramMetricsView({
  program,
  baseLanguage,
}: Readonly<IGroupProgramMeticsViewProps>) {
  const { organizationSlug: appContextOrgSlug, isRootDomain } = useAppContext();
  const { isLoading: isLoadingAuth } = useAuth({});

  const { setError } = useSnackbar();

  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const [updatedAt, setUpdatedAt] = useState(getUpdatedAt());

  const {
    models: {
      groupsModel,
      kpiModel,
      sessionsModel,
      traineeProfileModel,
      overtimeModel,
    },
    updateGroupsModel,
    updateTraineeProfileModel,
    updateSessionsModel,
  } = useProgramGroupTableContext();

  const countryOptions: IMultiSelectChipFilterOption[] = useMemo(
    () =>
      getCountryList({ countryCode: 'GB', languageCode: 'en' }).map(
        ({ key, countryName }) => ({
          id: key,
          label: countryName,
          color: 'gray',
          variant: 'outlined',
        }),
      ),
    [],
  );

  const tableRefs: ITableRefs = {
    groups: useRef<HTMLDivElement>(null),
    traineeTable: useRef<HTMLDivElement>(null),
    sessions: useRef<HTMLDivElement>(null),
  };
  const programSlug = program.metadata.id.current;
  const organizationSlug =
    appContextOrgSlug ??
    program.metadata.organization?.basic_info.subdomain.current ??
    'guider';
  const programTypeName =
    program?.program_details?.program_type?.program_type_text.common?.verb;
  const programName = program?.metadata.program_name;
  const programType = program?.program_details?.program_type;
  const guideOrganizationLabel =
    programType?.program_type_text.common?.guide?.singular ?? 'Guide';
  const traineeOrganizationLabel =
    programType?.program_type_text.common?.trainee?.singular ?? 'Trainee';
  const traineeOrganizationLabelPluralized =
    programType?.program_type_text.common?.trainee?.pluralized ?? 'Trainees';
  const guideOrganizationLabelPluralized =
    programType?.program_type_text.common?.guide?.pluralized ?? 'Guides';

  const concludeRelationshipKpiLabel =
    baseLanguage?.globals?.relationships?.conclude_relationship_kpi_label ?? '';

  const {
    organization: sanityOrganization,
    isLoadingOrganization,
    isValidatingOrganization,
  } = useOrganization({
    organizationSlug: organizationSlug,
    showHiddenProfileData: true,
  });

  const sanityOrganizationPersonalDetails =
    sanityOrganization?.personal_details;
  const isSanityOrganizationCountryEnabled =
    sanityOrganizationPersonalDetails?.country.enabled ?? false;
  const sanityCustomCountryLabel =
    sanityOrganizationPersonalDetails?.country.custom_label ?? 'Country';

  const {
    adminKPIsOrganization,
    isLoadingAdminKPIsOrganization,
    revalidateAdminKPIsOrganization,
    isValidatingAdminKPIsOrganization,
  } = useAdminKPIsOrganization({
    params: {
      filterModel: kpiModel.filterModel,
    },
  });

  const adminKPIOrganization = adminKPIsOrganization?.at(0);
  const organizationMemberCount = adminKPIOrganization?.memberCount ?? 0;
  const organizationSessionCount = adminKPIOrganization?.sessionCount ?? 0;

  const {
    adminKPIsGroupProgram,
    isLoadingAdminKPIsGroupProgram,
    revalidateAdminKPIsGroupProgram,
    isValidatingAdminKPIsGroupProgram,
  } = useAdminKPIsGroupProgram({
    params: {
      programSlug,
      filterModel: kpiModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({ message: error.message, title: 'Failed to get KPIs' });
      },
    },
  });
  const adminKPIGroupProgram = adminKPIsGroupProgram?.at(0);
  const memberCount = adminKPIGroupProgram?.memberCount ?? 0;
  const traineesWithoutRelationship =
    adminKPIGroupProgram?.traineesWithoutRelationship ?? 0;
  const openGroupRelationshipCount =
    adminKPIGroupProgram?.openGroupRelationshipCount ?? 0;
  const activeRelationshipCount =
    adminKPIGroupProgram?.activeRelationshipCount ?? 0;
  const concludedRelationshipCount =
    adminKPIGroupProgram?.concludedRelationshipCount ?? 0;
  const sessionsCount = adminKPIGroupProgram?.sessionCount ?? 0;
  const groupSessionsDuration =
    adminKPIGroupProgram?.groupSessionsDuration ?? 0;

  // Overtime Graph

  const {
    isLoadingAdminOvertimeCharts,
    revalidateAdminOvertimeCharts,
    isValidatingAdminOvertimeCharts,
  } = useAdminOvertimeCharts({
    params: {
      ...(programSlug && { programSlug }),
      filterModel: overtimeModel.filterModel,
    },
  });

  // Group Table
  const {
    adminChartsGroupProgramRelationships,
    revalidateAdminChartsGroupProgramRelationships,
    isLoadingAdminChartsGroupProgramRelationships,
    isValidatingAdminChartsGroupProgramRelationships,
  } = useAdminChartsGroupProgramRelationships({
    params: {
      programSlug,
      ...(isRootDomain && { organizationSlug }),
      page: groupsModel.paginationModel.page + 1,
      pageLimit: groupsModel.paginationModel.pageSize,
      sortModel: groupsModel.sortModel,
      filterModel: groupsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Groups Chart',
        });
      },
    },
  });
  const groupTableRows = adminChartsGroupProgramRelationships ?? [];
  const groupTableColumns = getGroupColumns({
    guideLabel: guideOrganizationLabel,
    traineeLabel: traineeOrganizationLabel,
    countryLabel: sanityCustomCountryLabel,
    countryOptions,
    countryEnabled: isSanityOrganizationCountryEnabled,
  });

  // Trainees Table
  const {
    adminChartsGroupProgramTraineeProfiles,
    revalidateAdminChartsGroupProgramTraineeProfiles,
    isLoadingAdminChartsGroupProgramTraineeProfiles,
    isValidatingAdminChartsGroupProgramTraineeProfiles,
  } = useAdminChartsGroupProgramTraineeProfiles({
    params: {
      programSlug,
      ...(isRootDomain && { organizationSlug }),
      page: traineeProfileModel.paginationModel.page + 1,
      pageLimit: traineeProfileModel.paginationModel.pageSize,
      sortModel: traineeProfileModel.sortModel,
      filterModel: traineeProfileModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Trainee Profiles Chart',
        });
      },
    },
  });
  const traineesTableRows = adminChartsGroupProgramTraineeProfiles ?? [];
  const traineeTableColumns = getGroupTraineeProfileColumns({
    countryLabel: sanityCustomCountryLabel,
    countryOptions,
    countryEnabled: isSanityOrganizationCountryEnabled,
  });

  // Sessions Table
  const {
    adminChartsGroupProgramSessions,
    revalidateAdminChartsGroupProgramSessions,
    isLoadingAdminChartsGroupProgramSessions,
    isValidatingAdminChartsGroupProgramSessions,
  } = useAdminChartsGroupProgramSessions({
    params: {
      programSlug,
      ...(isRootDomain && { organizationSlug }),
      page: sessionsModel.paginationModel.page + 1,
      pageLimit: sessionsModel.paginationModel.pageSize,
      sortModel: sessionsModel.sortModel,
      filterModel: sessionsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Sessions Chart',
        });
      },
    },
  });
  const sessionsTableRows = adminChartsGroupProgramSessions ?? [];
  const sessionsTableColumns = getGroupSessionColumns({
    guideOrganizationLabel,
    traineePluralized: traineeOrganizationLabelPluralized,
    countryLabel: sanityCustomCountryLabel,
    countryOptions,
    countryEnabled: isSanityOrganizationCountryEnabled,
  });

  const isLoading =
    isLoadingAdminChartsGroupProgramRelationships ||
    isLoadingAdminChartsGroupProgramSessions ||
    isLoadingAdminChartsGroupProgramTraineeProfiles ||
    isLoadingAdminKPIsOrganization ||
    isLoadingAdminKPIsGroupProgram ||
    isLoadingAdminOvertimeCharts ||
    isLoadingOrganization ||
    isLoadingAuth;

  const isValidating =
    isValidatingAdminChartsGroupProgramRelationships ||
    isValidatingAdminChartsGroupProgramSessions ||
    isValidatingAdminChartsGroupProgramTraineeProfiles ||
    isValidatingAdminKPIsGroupProgram ||
    isValidatingAdminOvertimeCharts ||
    isValidatingOrganization ||
    isValidatingAdminKPIsOrganization;

  const refresh = () => {
    setUpdatedAt(getUpdatedAt());
    revalidateAdminChartsGroupProgramRelationships();
    revalidateAdminChartsGroupProgramSessions();
    revalidateAdminChartsGroupProgramTraineeProfiles();
    revalidateAdminOvertimeCharts();
    revalidateAdminKPIsGroupProgram();
    revalidateAdminKPIsOrganization();
  };

  return (
    <PageView
      title={`${programName}`}
      subtitles={[`${programTypeName}`]}
      headerSx={{
        alignItems: 'flex-start',
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? '24px' : 0,
        textWrap: 'nowrap',
      }}
      headerElement={
        <Stack
          direction={'column'}
          spacing={0}
          width={'100%'}
          alignItems={'flex-end'}
          flexWrap={isMobile ? 'wrap' : undefined}
          paddingRight={1}
        >
          <Button
            variant="textWithIcon"
            startIcon={<Refresh />}
            label="Refresh data"
            loading={isLoading || isValidating}
            onClick={refresh}
          />
          <Text
            text={updatedAt}
            variant="subtitle2"
            color={palette.paper.text.disabled}
            sx={{ textWrap: 'nowrap' }}
          />
        </Stack>
      }
      sx={{ backgroundColor: palette.paper.background.primary }}
    >
      <Stack direction="row" width="100%" py={3} px={1}>
        <MetricsProgramGroupFilterContainer
          countryOptions={countryOptions}
          countryEnabled={isSanityOrganizationCountryEnabled}
        />
      </Stack>
      <Stack direction="column" width="100%" gap={5}>
        <KeyStatList
          tableRefs={tableRefs}
          keyStats={getGroupKeyStats({
            usersCount: memberCount,
            traineesNotInGroupCount: traineesWithoutRelationship,
            organizationMemberCount,
            sessionsCount,
            organizationSessionCount,
            concludedGroupsCount: concludedRelationshipCount ?? 0,
            openGroupsCount: openGroupRelationshipCount,
            activeGroupsCount: activeRelationshipCount,
            groupSessionsDuration: groupSessionsDuration,
            traineeLabelPluralized:
              traineeOrganizationLabelPluralized ?? 'trainees',
            concludedRelationshipKpiLabel: concludeRelationshipKpiLabel,
          })}
          loading={
            isLoadingAdminKPIsGroupProgram || adminKPIGroupProgram === undefined
          }
        />
        <MuiGrid container rowSpacing={3} direction="row">
          <StackedChartContainer
            programSlug={programSlug}
            labels={{
              guidePluralized: guideOrganizationLabelPluralized,
              traineePluralized: traineeOrganizationLabelPluralized,
            }}
            filterModel={kpiModel.filterModel}
          />
        </MuiGrid>
        <div ref={tableRefs['groups']}>
          <MongoMetricsContainer
            filename="Groups"
            reportPath="group"
            metricsTableProps={{
              heading: `Groups`,
              ...groupsModel,
              rows: groupTableRows,
              columns: groupTableColumns,
              loading: isLoadingAdminChartsGroupProgramRelationships,
              onFilterModelChange: (filterModel) =>
                updateGroupsModel({ filterModel: filterModel as IFilterModel }),
              filterMode: 'server',
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateGroupsModel({ sortModel });
              },
              pageSizeOptions: [5, 10, 25, 50, 75, 100],
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateGroupsModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
        <div ref={tableRefs['traineeTable']}>
          <MongoMetricsContainer
            filename={`${traineeOrganizationLabel} profiles`}
            reportPath="group/trainee-profiles"
            metricsTableProps={{
              ...traineeProfileModel,
              heading: `${traineeOrganizationLabel} profiles`,
              rows: traineesTableRows,
              columns: traineeTableColumns,
              loading: isLoadingAdminChartsGroupProgramTraineeProfiles,
              onFilterModelChange: (filterModel) =>
                updateTraineeProfileModel({
                  filterModel: filterModel as IFilterModel,
                }),
              filterMode: 'server',
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateTraineeProfileModel({ sortModel });
              },
              pageSizeOptions: [5, 10, 25, 50, 75, 100],
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateTraineeProfileModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>

        <div ref={tableRefs['sessions']}>
          <MongoMetricsContainer
            filename={`Sessions`}
            reportPath="group/sessions"
            metricsTableProps={{
              ...sessionsModel,
              heading: 'Sessions',
              rows: sessionsTableRows,
              columns: sessionsTableColumns,
              loading: isLoadingAdminChartsGroupProgramTraineeProfiles,
              onFilterModelChange: (filterModel) =>
                updateSessionsModel({
                  filterModel: filterModel as IFilterModel,
                }),
              filterMode: 'server',
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateSessionsModel({ sortModel });
              },
              pageSizeOptions: [5, 10, 25, 50, 75, 100],
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateSessionsModel({
                  paginationModel: model,
                });
              },
            }}
          />
        </div>
      </Stack>
    </PageView>
  );
}
